import "../graphiql-overrides.css";
import "../styles.css";
import "@bay1/ui/HelveticaNow.css";
import "@bay1/ui/spaceMono.css";
import "@bay1/ui/styles.css";

import { CommonAppContextProvider, GraphiqlContextProvider } from "@bay1/data";
import GlobalStyle from "@bay1/ui";
import * as Fathom from "fathom-client";
import type { AppProps } from "next/app";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import { useEffect } from "react";

Router.events.on("routeChangeComplete", (as, routeProps) => {
  if (!routeProps.shallow) {
    Fathom.trackPageview();
  }
});

const Home = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    Fathom.load(`${process.env.NEXT_PUBLIC_FATHOM_SITE_ID}`, {
      url: "https://vegetableplate.highnote.com/script.js",
      includedDomains: ["highnote.com", "www.highnote.com"],
    });
  }, []);

  return (
    <>
      <Head>
        <link href="/favicon.svg" rel="shortcut icon" />
        <script
          async
          src="https://tag.clearbitscripts.com/v1/pk_cdc48e53009f299766ed5499fd2a57e3/tags.js"
          referrerPolicy="strict-origin-when-cross-origin"
        />
      </Head>
      <GlobalStyle />

      <CommonAppContextProvider router={router}>
        <GraphiqlContextProvider>
          <Component {...pageProps} />
        </GraphiqlContextProvider>
      </CommonAppContextProvider>
    </>
  );
};

export default Home;
